import React from "react";
import Logo from "../assets/fundawheelslogo.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import mahlespares from "../assets/mahlespares.png"
import mahlesparesmobile from "../assets/mahlesparesmobile.png"
import './main.css'
import fwheels from '../assets/Fwheels.png'
import fwheelsmobile from '../assets/Fwheelsmobile.png'
import Carousel from 'react-bootstrap/Carousel';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useRef, useState } from "react";
import AboutUsSlide1 from '../assets/abs1.jpg'
import AboutUsSlide2 from '../assets/abs2.jpg'
import AboutUsSlide3 from '../assets/abs3.jpg'
import FooterLogo from '../assets/fundawheelslogo.png'
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



function MyVerticallyCenteredModal(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    About Us
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="about-modal">

                Fundawheels was established as a Classic and Youngtimer car purveyor. The collection has grown to include timeless beauties. Fundawheels has as it's anchor client, FundaMedical, provided patient transport and car rentals for associated needs.


                <br /><br /><h2 className="text-center text-white">Our mission is to:</h2><br /><br />

                Maintain the personal aspect of the business of motoring<br />
                Provide a bespoke alternative to the digital offerings<br />
                Maintain a substantial Classic and young time collection<br />
                Be involved in all aspects of our beloved Motoring world<br />
                Be commercially viable, and sustainable, without being opportunistic and short sited.<br />

                <br /><br /><h2 className="text-center text-white">Achievements:</h2><br /><br />

                South African National Concours winner in class 1950 to 1959 winner - Mercedes 300d Adenauer<br />
                Winner Daytona Rally 2012 - Lamborghini Superleggera<br />
                2021 24 hour lemons finisher<br />
                2022 24 hour lemons finisher<br />
                Corsa del Capo - Valentino Balboni 2022<br />
                2023 Mercedes Benz AMG GT3 9 hour Endurance Sponsor - driver Clint Weston<br />
                2023 Cape 1000 1959 participant<br />

                <br /><br /><h2 className="text-center text-white">Restorations:</h2><br /><br />
                1949 Mercedes V170S<br />
                1958 Mercedes 300d Adenauer<br />
                1967 Mercedes 230 fintail<br />
                1979 Rolls Royce Silver Shadow 2<br />
                1998 Lamborghini Diablo SV<br />
                2006 Lamborghini Murcielago<br />
                1994 Mercedes 190E 2.3-16 "Senna"<br />
                1996 W124 320CE Cabriolet Sportline<br />
                1998 Mercedes Benz W140 CL500<br />
                1989 Mercedes Benz 560 SEC<br />
                1990 Mercedes Benz 560 SEL - long wheel base<br />
                1992 Opel Kadett GSi - 16vS "SuperBoss"<br />
                2006 Ferrari 599 F1 - "Fiorano"- 60 anniversary<br />
                1990 Mercedes Benz R129 600 SL<br />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}




function Main() {
    const [modalShow, setModalShow] = React.useState(false);
    //Selection change state
    const [selection, setSelection] = useState("Select option");
    const formRef = useRef(null);


    function handleChange(event) {
        setSelection(event.target.value);

    }
    //end
    const googleMap = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_SERVICE_MAP_KEY}&q=Space+Riverview+Office+Park`;
    //email sending function
    const PUBLIC_KEY = `${process.env.REACT_APP_PUBLIC_KEY}`;
    const SERVICE_GENERAL_QUERIES_ID = `${process.env.REACT_APP_SERVICE_GENERAL_QUERIES_ID}`;
    const SERVICE_GENERAL_QUERIES_TEMPLATE_ID = `${process.env.REACT_APP_SERVICE_GENERAL_QUERIES_TEMPLATE_ID}`;
    const SERVICE_SPARE_ORDER_ID = `${process.env.REACT_APP_SERVICE_SPARE_ORDER_ID}`;
    const SERVICE_SPARE_ORDER_TEMPLATE_ID = `${process.env.REACT_APP_SERVICE_SPARE_ORDER_TEMPLATE_ID}`;

    const sendingAnEmail = (e) => {
        e.preventDefault();



        if (selection === "General Queries") {
            emailjs.sendForm(SERVICE_GENERAL_QUERIES_ID, SERVICE_GENERAL_QUERIES_TEMPLATE_ID, e.target, PUBLIC_KEY
            ).then(
                (resp) => {
                    toast.success("Query successfully sent.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000
                    });
                    formRef.current.reset();
                },
                (error) => {
                    toast.error("Query not successfully sent. Please fill in required fields.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000
                    });
                }
            );
        } else if (selection === "Car Spare Order") {
            emailjs.sendForm(SERVICE_SPARE_ORDER_ID, SERVICE_SPARE_ORDER_TEMPLATE_ID, e.target, PUBLIC_KEY
            ).then(
                (resp) => {
                    toast.success("Order query successfully sent.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000
                    });
                    formRef.current.reset();
                },
                (error) => {
                    toast.error("Order query was not successfully sent.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000
                    });
                }
            );
        }
    };

    return (
        <div className="App">
            <section id="navigation">
                <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary ">
                    <div className="container-fluid">
                        <img className="logoImg" src={Logo} alt="logo" title="fundawheels-logo" />
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-pills nav-fill">
                                <li className="nav-item">
                                    <a className="nav-link" aria-current="page" href="#">Home</a>
                                </li>
                                <NavDropdown
                                    id="nav-dropdown-dark-example"
                                    title="Products"
                                    menuVariant="light"
                                >
                                    <NavDropdown.Item href="#contact-us">Funda Wheels</NavDropdown.Item>
                                    <NavDropdown.Item href="#contact-us">Funda Drive</NavDropdown.Item>
                                    <NavDropdown.Item href="#contact-us">Funda Spares</NavDropdown.Item>

                                </NavDropdown>
                                <li className="nav-item">
                                    <a className="nav-link" href="#about-us">About Us</a>
                                </li>




                                <li className="nav-item">
                                    <a className="nav-link" href="#contact-us">Contact Us</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
            </section>

            <section id="home">
                <div className="mob">
                    <Carousel interval={null} controls={false} className="carousel slider-container">

                        <Carousel.Item>

                            <img
                                className=" w-100 hide-on-small-screen"
                                src={mahlespares}
                                alt="Slider image"
                            />
                            <img
                                className=" w-100 show-on-small-screen"
                                src={mahlesparesmobile}
                                alt="Slider image"
                            />

                            <Carousel.Caption className="caption ">
                                <h2><strong> FundaSpares</strong></h2>
                                <p>
                                    Classic car parts - email or call us now, for your pre 2000 spare parts.
                                    FundaWheels and Mahle Classic Line supplying filters, radiators and OEM engine parts.
                                </p>
                                <a href="https://web.tecalliance.net/mahle-catalog/en/home" target="_blank"><button className="btn mhlBtn">View Catalogue</button></a>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>

                            <img
                                className=" w-100 hide-on-small-screen"
                                src={fwheels}
                                alt="Slider image"
                            />
                            <img
                                className=" w-100 show-on-small-screen"
                                src={fwheelsmobile}
                                alt="Slider image"
                            />

                            <Carousel.Caption className="caption">
                                <h2><strong>FundaWheels</strong></h2>
                                <p>
                                    Our mission is to maintain the human aspect, while using digital efficiency.
                                    Speak to a person, if you need short term transport, classic spare parts or other motoring assistance.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>

                            <img
                                className=" w-100 hide-on-small-screen"
                                src={fwheels}
                                alt="Slider image"
                            />
                            <img
                                className=" w-100 show-on-small-screen"
                                src={fwheelsmobile}
                                alt="Slider image"
                            />


                            <Carousel.Caption className="caption third">
                                <h2><strong>FundaDrive</strong></h2>
                                <p>
                                    Personalised and affordable car hire.<br />

                                    Do you need a taxi that you can trust - call us to book your trip.
                                    We operate in and around Midrand, Johannesburg and Pretoria.

                                    - Personalised location-to-location transfer.<br />
                                    -  Chauffeur<br />
                                    -  Shuttle taxi service<br />
                                    - non emergency/critical patient transport for Medical Legal assessments
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>

            </section>

            <section id="about-us">
                <br />
                <div className="">
                    <div className="row">

                        <div className="col-md-6">
                            <h1 className="container text-center text-white">About Us</h1>
                            <div className="container">
                                <p className="text-white">Fundawheels was established as a Classic and Youngtimer car purveyor.
                                    The collection has grown to include timeless beauties.
                                    Fundawheels has as it's anchor client,  FundaMedical, provided patient transport and car rentals for associated needs.
                                </p>
                                <br />
                                <h2 className="text-center text-white mobileView">Our mission is to:</h2>
                                <br />
                                <ol className="list-class text-white mobileView">
                                    <li>Maintain the personal aspect of the business of motoring</li>
                                    <li>Provide a bespoke alternative to the digital offerings</li>
                                    <li>Maintain a substantial Classic and young time collection</li>
                                    <li>Be involved in all aspects of our beloved Motoring world</li>
                                    <li>Be commercially viable, and sustainable, without being opportunistic and short sited.</li>
                                </ol>
                                <br />
                                <h2 className="text-center text-white mobileView">Achievements:</h2>
                                <br />
                                <ol className="list-class text-white">
                                    <li className="mobileView">South African National Concours winner in class 1950 to 1959 winner - Mercedes 300d Adenauer</li>
                                    <li className="mobileView">Winner Daytona Rally 2012 - Lamborghini Superleggera</li>
                                    <li className="mobileView">2021 24 hour lemons finisher</li>
                                    <li className="mobileView">2022 24 hour lemons finisher</li>
                                    <li className="mobileView">Corsa del Capo - Valentino Balboni 2022</li>
                                    <li className="mobileView">2023 Mercedes Benz AMG GT3 9 hour Endurance Sponsor - driver Clint Weston</li>
                                    <li className="mobileView">2023 Cape 1000 1959  participant</li>
                                    <li><button className="btn readMorebtn" onClick={() => setModalShow(true)}>Read More</button></li>
                                </ol>

                            </div>


                            <MyVerticallyCenteredModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </div>

                        <div className="col-md-6 mobileView">
                            <div className="image-slider">
                                <Carousel indicators={false} controls={false} interval={5000} className="carousel-fade slider-containerAb">
                                    <Carousel.Item>
                                        <img style={{ height: '100vh' }}
                                            className="d-block "
                                            src={AboutUsSlide1}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img style={{ height: '100vh' }}
                                            className="d-block"
                                            src={AboutUsSlide2}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img style={{ height: '100vh' }}
                                            className="d-block"
                                            src={AboutUsSlide3}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section id="products">
                <br />
                <h1 className="text-center">Services</h1>
                <br />
                <div className="container ">
                    <div className="row mt-5d-flex justify-content-center">

                        <div className="col-sm-4 card-size">
                            <div className="card h-100 one effect">
                                <div className="card-body ">
                                    <h2 className="card-title">Classic Spare Parts</h2>
                                    <p className="card-text"> FundaWheels has partnered up with Mahle to supply you with the comprehensive range of Classic Mahle spare parts. We have everything you would need For a restoration project.</p>
                                </div>
                                <div className="btn-group ">
                                    <div className="card-footer "><a className="btn btnClassic btn-sm" href="#contact-us">More Info</a></div>
                                    <div className="card-footer"><a className="btn btnClassic btn-sm" href="https://web.tecalliance.net/mahle-catalog/en/home" target="_blank">Catalogue</a></div>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-4 card-size">
                            <div className="card h-100 mb-3 two effect  ">
                                <div className="card-body">
                                    <h2 className="card-title">Taxi</h2>
                                    <p className="card-text">FundaDrive offers personalised location-to-location transfer, chauffeur, and shuttle taxi service. We offer a variety of safe, clean and quality vehicles.</p>
                                </div>
                                <div className="card-footer"><a className="btn servicesBtn btn-sm" href="#contact-us">More Info</a></div>
                            </div>
                        </div>
                        <div className="col-sm-4 card-size">
                            <div className="card h-100 mb-3 three effect  ">
                                <div className="card-body">
                                    <h2 className="card-title">Car Hire For Events</h2>
                                    <p className="card-text">Select from a wide range of luxurious classic vehicles to add to your events. We have a wonderful team to help you identify the best vehicle for your event according to your wishes.</p>
                                </div>
                                <div className="card-footer"><a className="btn servicesBtn btn-sm" href="#contact-us">More Info</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="m-hidden" style={{ color: '#FFF' }}> -</span>
                <div className="container">
                    <div className="row mt-5d-flex justify-content-center">
                        <div className="col-sm-4 card-size">
                            <div className="card h-100 four effect">
                                <div className="card-body">
                                    <h2 className="card-title">Car Rental</h2>
                                    <p className="card-text"> Contact us for stress-free Car hire with a variety of premium vehicles to choose from. Each vehicle is as unique as your need.</p>
                                </div>
                                <div className="card-footer"><a className="btn servicesBtn btn-sm" href="#contact-us">More Info</a></div>
                            </div>
                        </div>

                        <div className="col-sm-4 card-size">
                            <div className="card h-100 mb-4 five effect">
                                <div className="card-body">
                                    <h2 className="card-title">Car Sales</h2>
                                    <p className="card-text">Youngtimer, Classic and vintage used car sales...</p>
                                </div>
                                <div className="card-footer"><a className="btn servicesBtn btn-sm" href="#contact-us">More Info</a></div>
                            </div>
                        </div>

                        <div className="col-sm-4 card-size">
                            <div className="card h-100 mb-4 one effect  hv12">
                                <div className="card-body">
                                    <h2 className="card-title">Coming soon!</h2>
                                    <p className="card-text">Personalised classic restoration services</p>
                                    <p className="card-text">Personalised classic car maintenance and service</p>
                                </div>
                                <div className="card-footer"><a className="btn btnClassic btn-sm" href="#contact-us">More Info</a></div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <section id="contact-us">
                <div className="container">
                    <div>
                        <br />
                        <h1 className="text-white text-center">Contact Us</h1>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <form ref={formRef} onSubmit={sendingAnEmail}>
                                <div className="">
                                    <div className="">
                                        <input type="text" name="fullname" className="form-control" placeholder="FullName" required />
                                    </div>
                                    <br />
                                    <div className="">
                                        <input type="text" name="contactnumber" className="form-control" placeholder="Contact Number" />
                                    </div>
                                    <br />
                                    <div className="">
                                        <input type="email" name="user_email" className="form-control" placeholder="example@email.com" required />
                                    </div>

                                    <br />
                                    <div className="">
                                        <select className="form-control" name="subject" value={selection} onChange={handleChange} >
                                            <option value="Select option" disabled>Select option</option>
                                            <option value="Car Spare Order">Classic Spare Order</option>
                                            <option value="General Queries">General Queries</option>
                                        </select>
                                    </div>
                                    <br></br>
                                    {selection === "Car Spare Order" && (
                                        <div>
                                            <input type="text" name="part_number" className="form-control" placeholder="Mahle Quotation Number" required />
                                            <br />
                                        </div>

                                    )}
                                    <div className="">
                                        <textarea cols="5" rows="9" name="message" className="form-control" placeholder="Message">
                                        </textarea>
                                    </div>
                                </div>
                                <br />
                                <div className="">
                                    <button className="btn submtBtn">Submit</button>
                                </div>

                            </form>
                        </div>

                        <div className="col-md-6 mobileView">
                            <div className="google-map-code">
                                <iframe src={googleMap} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="footer-section">
                <footer className="py-4 bg-light">
                    <div>
                        <div className="container">
                            <div className="row">

                                <div className="col-sm-4 ">
                                    <div className="card footer-card" style={{ border: 'none' }}>
                                        <div className="card-body ">
                                            <img src={FooterLogo} style={{ height: '60px' }} alt="logo" />

                                            <div className="">
                                                <h6 className="card-text">Contact Details</h6>
                                                <p className="">Email: info@fundawheels.co.za
                                                    <br />
                                                    Contact Number: 010 594 9470
                                                </p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card footer-card" style={{ border: 'none' }}>
                                        <span style={{ height: '60px' }}></span>
                                        <div className="card-body">
                                            <h6 className="card-text">Address</h6>
                                            <p>Riverview Office Park</p>
                                            <p>Unit 3 Janadel Avenue</p>
                                            <p>Halfway Gardens</p>
                                            <p>South Africa</p>
                                            <p>Midrand</p>
                                            <p>1684</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card footer-card" style={{ border: 'none' }}>
                                        <span style={{ height: '60px' }}></span>
                                        <div className="card-body">
                                            <h6 className="card-text">Office Hours</h6>
                                            <p>Mon - 07:00 - 17:00</p>
                                            <p>Tue - 07:00 - 17:00</p>
                                            <p>Wed - 07:00 - 17:00</p>
                                            <p>Thu - 07:00 - 17:00</p>
                                            <p>Fri - 07:00 - 17:00</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" container px-4 px-lg-5"><p className="m-0 text-center ">
                        Copyright &copy; www.fundawheels.co.za 2023</p>
                    </div>
                </footer>
            </section>
        </div >
    );
}

export default Main;